
import React from 'react'

import { I18n } from '@front/volcanion'
import { SubmitButton, NumberField } from '@front/squirtle'
import { Grid, Icon, Typography } from '@mui/material'

const PaymentSection = (props) => (
  <Grid container item xs={12} spacing={2}>
    <Grid item xs={12}>
      <NumberField
        name='base'
        type='float'
        label={I18n.t('quotation.amount')}
        InputProps={{ endAdornment: <Icon color='primary'> {'euro'} </Icon> }}
        required
      />
    </Grid>
    <Grid item xs={12}>
      <NumberField
        name='fees'
        type='float'
        label={I18n.t('quotation.fees')}
        InputProps={{ endAdornment: <Icon color='primary'> {'euro'} </Icon> }}
      />
    </Grid>
    <Grid item xs={12}>
      <NumberField
        name="prepaid"
        type='float'
        label={I18n.t('quotation.prepaid')}
        InputProps={{ endAdornment: <Icon color='primary'> {'euro'} </Icon> }}
      />
    </Grid>
    <Grid item xs={12}>
      <NumberField
        name="option_amount"
        type='float'
        label={I18n.t('quotation.option_amount')}
        InputProps={{ endAdornment: <Icon color='primary'> {'euro'} </Icon> }}
        disabled
      />
    </Grid>
    <Grid item xs={12}>
      <NumberField
        name="driver_package_fee"
        type='float'
        label={I18n.t('quotation.driver_package_fee')}
        InputProps={{ endAdornment: <Icon color='primary'> {'euro'} </Icon> }}
        disabled
      />
    </Grid>
  </Grid>
)

const SimulatorDialog = ({
  transportStatus,
  transportStatusColor,
  orderStatus,
  orderStatusColor,
  nextButtonLabel,
  isPayment,
  isOrderCompleted,
}) => {
  return (
    <Grid container xs spacing={2}>
      <Grid container item xs={12} direction='row' justifyContent='flex-start'>
        <Typography variant={'h5'} color={orderStatusColor} fontWeight={400}>{orderStatus}</Typography>
        <Typography variant={'h5'} marginX={1}>|</Typography>
        <Typography variant={'h5'} color={transportStatusColor} fontWeight={400}>{transportStatus}</Typography>
      </Grid>
      {!isOrderCompleted && isPayment && <PaymentSection />}
      {!isOrderCompleted &&
        <Grid container item xs={12} spacing={2}>
          <Grid item xs={12}>
            <SubmitButton fullWidth extra={{ submitType: isPayment ? 'payment' : 'next' }} label={nextButtonLabel} />
          </Grid>
          <Grid item xs={12}>
            <SubmitButton fullWidth extra={{ submitType: 'cancel' }} label={I18n.t('transport.status.not_loaded')} />
          </Grid>
        </Grid>
      }
    </Grid>
  )
}

export default React.memo(SimulatorDialog)
